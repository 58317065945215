import React from "react";
import { MdFavoriteBorder, MdFlashOn, MdStarBorder, MdVisibility, MdWhatshot } from "react-icons/md";
import "./hotlinks.scss"


const Hotlinks = () =>{
    return(
        <>
            <div className="hotLinks">
                <div className="hotLinksWrapper">
                    <div className="item">
                        <a>
                            <MdStarBorder style={{fontSize:"30px"}}/>
                            <span>फ़ीचर समाचार</span>
                        </a>
                    </div>
                    <div className="item">
                        <a>
                            <MdFavoriteBorder style={{fontSize:"30px"}}/>
                            <span>सबसे लोकप्रिय</span>
                        </a>
                    </div>
                    <div className="item">
                        <a>
                            <MdWhatshot style={{fontSize:"30px"}}/>
                            <span>ताज़ा समाचार</span>
                        </a>
                    </div>
                    <div className="item">
                        <a>
                            <MdFlashOn style={{fontSize:"30px"}}/>
                            <span>ट्रेंडिंग न्यूज़</span>
                        </a>
                    </div>
                    <div className="item">
                        <a>
                            <MdVisibility style={{fontSize:"30px"}}/>
                            <span>ज्यादा देखी गयी</span>
                        </a>
                    </div>
                </div>
                <div className="new--ticker">
                    <div className="container">
                        <div className="title">
                            <h2>न्यूज़ अपडेट</h2>
                            <span>(अपडेट 20 मिनट पहले किया )</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hotlinks;
import React, { useEffect, useState } from "react";
import "./navbarBottom.scss"
import { MdFavoriteBorder, MdFlashOn, MdKeyboardArrowDown, MdNavigateNext, MdStarBorder, MdVisibility } from "react-icons/md";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import {HiViewGrid} from "react-icons/hi";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";

const NavbarBottom = () => {
    
    const [fix,setFix] = useState(false)
    const [data,setData] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [dropdown,setDropdown] = useState(false);
    const navigate = useNavigate();
    const handleDropdown = (e) => {
        setDropdown(!dropdown);
    }

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BACKEND_URL}/indiaslider`)
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            setData(data)
        })
    },[])


    const handleClick = (id,name) =>{
        
        navigate(`/view/${name}/${id}`)
    }

    const handleScroll = (e) =>{
        if(window.scrollY >= 200){
            setFix(true)
        }
        else{
            setFix(false)
        }

    }
    window.addEventListener("scroll", handleScroll)

    const handleHome = () => {
        navigate('/')
    }

    const handleDrop = () => {
        setIsOpen(!isOpen); // Toggles the state of the dropdown
      };
    return(
        <>
        <div className={fix ? "navbarBottom fixed": "navbarBottom"}>
            <div className="navBarBottomWrapper">
                    <div className="item">
                        <span onClick={handleHome}>होम</span>
                    </div>
                    <div className="item" onClick={handleDropdown}>
                        <span>भारत</span>
                        <MdKeyboardArrowDown style={{fontSize:"20px"}}/>
                                {dropdown && (
                                    <div className="dropDownMenu">
                                        <div className="dropDownMenuWrapper">
                                            <div className="left">
                                                <div className="item">
                                                    <span>सभी</span>
                                                    <MdNavigateNext
                                                        style={{
                                                            fontSize:"20px"
                                                        }}
                                                    />
                                                </div>
                                                <div className="item">
                                                    <span>उत्तर भारत</span>
                                                    <MdNavigateNext
                                                        style={{
                                                            fontSize:"20px"
                                                        }}
                                                    />
                                                </div>
                                                <div className="item">
                                                    <span>दक्षिण भारत</span>
                                                    <MdNavigateNext
                                                        style={{
                                                            fontSize:"20px"
                                                        }}
                                                    />
                                                </div>
                                                <div className="item">
                                                    <span>पूर्वी भारत</span>
                                                    <MdNavigateNext
                                                        style={{
                                                            fontSize:"20px"
                                                        }}
                                                    />
                                                </div>
                                                <div className="item">
                                                    <span>पश्चिम भारत</span>
                                                    <MdNavigateNext
                                                        style={{
                                                            fontSize:"20px"
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {data &&
                                                <div className="right">
                                                    <div className="rightTop">
                                                        <div onClick={()=>handleClick(data[0].id,data[0].name)} className="item">
                                                            <div className="img">
                                                                <a className="thumb">
                                                                    <img src={`https://drive.google.com/thumbnail?id=${data[0].image.image1}&sz=w1000`} alt=""/>
                                                                </a>
                                                                <a className="cat">{data[0].category}</a>
                                                                <a className="icon">
                                                                    <MdVisibility
                                                                    style={{fontSize:"20px"}}
                                                                    />
                                                                </a>
                                                            </div>
                                                            <a className="title">{data[0].heading}</a>
                                                        </div>
                                                        <div onClick={()=>handleClick(data[1].id,data[0].name)} className="item">
                                                            <div className="img">
                                                                <a className="thumb">
                                                                    <img src={`https://drive.google.com/thumbnail?id=${data[1].image.image1}&sz=w1000`} alt=""/>
                                                                </a>
                                                                <a className="cat">{data[1].category}</a>
                                                                <a className="icon">
                                                                    <MdStarBorder
                                                                    style={{fontSize:"20px"}}
                                                                    />
                                                                </a>
                                                            </div>
                                                            <a className="title">{data[1].heading}</a>
                                                        </div>
                                                        <div onClick={()=>handleClick(data[2].id,data[0].name)} className="item">
                                                            <div className="img">
                                                                <a className="thumb">
                                                                    <img src={`https://drive.google.com/thumbnail?id=${data[2].image.image1}&sz=w1000`} alt=""/>
                                                                </a>
                                                                <a className="cat">{data[2].category}</a>
                                                                <a className="icon">
                                                                    <MdFlashOn
                                                                    style={{fontSize:"20px"}}
                                                                    />
                                                                </a>
                                                            </div>
                                                            <a className="title">{data[2].heading}</a>
                                                        </div>
                                                        <div onClick={()=>handleClick(data[3].id,data[0].name)} className="item">
                                                            <div className="img">
                                                                <a className="thumb">
                                                                    <img src={`https://drive.google.com/thumbnail?id=${data[3].image.image1}&sz=w1000`} alt=""/>
                                                                </a>
                                                                <a className="cat">{data[3].category}</a>
                                                                <a className="icon">
                                                                    <MdFavoriteBorder
                                                                    style={{fontSize:"20px"}}
                                                                    />
                                                                </a>
                                                            </div>
                                                            <a className="title">{data[3].heading}</a>
                                                        </div>
                                                    </div>
                                                    <div className="rightBottom">
                                                        <a className="prew" title="Previous">
                                                            <FaLongArrowAltLeft style={{fontSize:"18px"}}/>
                                                        </a>
                                                        <a className="all" title="View All">
                                                            <HiViewGrid style={{fontSize:"18px"}}/>
                                                        </a>
                                                        <a className="next" title="Next">
                                                            <FaLongArrowAltRight style={{fontSize:"18px"}}/> 
                                                        </a>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )}
                    </div>
                    <div className="item">
                    <Link
                        activeClass="active"
                        to="mainBody"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        duration={900}
                    >
                        <span>अंतरराष्ट्रीय</span>
                    </Link>
                    </div>
                    <div className="item">
                    <Link
                        activeClass="active"
                        to="mainBodyFour"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        duration={900}
                    >
                        <span>चुनाव & अर्थव्यवस्था</span>
                    </Link>
                    </div>
                    <div className="item">
                    <Link
                        activeClass="active"
                        to="mainBodyTwo"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        duration={900}
                    >
                        <span>स्पोर्ट्स & एंटेरटैनमेंट</span>
                    </Link>
                    </div>
                    <div className="item">
                    <Link
                        activeClass="active"
                        to="mainBodyThree"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        duration={900}
                    >
                        <span>हेल्थ & लाइफस्टाइल</span>
                    </Link>
                    </div>
                    <div className="item">
                    <Link
                        activeClass="active"
                        to="audioVideo"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        duration={900}
                    >
                        <span>वीडियो</span>
                    </Link>
                    </div>
                    <div className="item">
                        <span>ब्लॉग</span>
                    </div>
            </div>
                <div className="dropdownwrapper">
                        <span onClick={handleDrop} className="bars"><FaBars style={{color:"white", fontSize:"25px"}} />
                        {isOpen && (
                                    <ul className="ul">
                                        <li onClick={handleHome}>होम</li>
                                        <li>
                                        <Link
                                                activeClass="active"
                                                to="mainBody"
                                                spy={true}
                                                smooth={true}
                                                offset={-50}
                                                duration={900}
                                            >
                                                <span>भारत</span>
                                        </Link>
                                        </li>
                                        <li>
                                            <Link
                                                activeClass="active"
                                                to="mainBody"
                                                spy={true}
                                                smooth={true}
                                                offset={-50}
                                                duration={900}
                                            >
                                                <span>अंतरराष्ट्रीय</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                activeClass="active"
                                                to="mainBodyFour"
                                                spy={true}
                                                smooth={true}
                                                offset={-50}
                                                duration={900}
                                            >
                                                <span>चुनाव & अर्थव्यवस्था</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                activeClass="active"
                                                to="mainBodyTwo"
                                                spy={true}
                                                smooth={true}
                                                offset={-50}
                                                duration={900}
                                            >
                                                <span>स्पोर्ट्स & एंटेरटैनमेंट</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                activeClass="active"
                                                to="mainBodyThree"
                                                spy={true}
                                                smooth={true}
                                                offset={-50}
                                                duration={900}
                                            >
                                                <span>हेल्थ & लाइफस्टाइल</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                activeClass="active"
                                                to="audioVideo"
                                                spy={true}
                                                smooth={true}
                                                offset={-50}
                                                duration={900}
                                            >
                                                <span>वीडियो</span>
                                            </Link>
                                        </li>
                                        <li>ब्लॉग</li>
                                    </ul>
                                )}
                        </span> 
                </div>
        </div>
        </>
    )
}

export default NavbarBottom;
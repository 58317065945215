import React, { useEffect, useState } from 'react'
import './mainBodyTwo.scss';
import StyleTwo from '../styleTwo/StyleTwo';
import StyleOne from '../styleOne/StyleOne';
import { FaRegNewspaper } from 'react-icons/fa';
import { GoMegaphone } from 'react-icons/go' ;
import { useNavigate } from 'react-router-dom';

const MainBodyTwo = () => {

    const[data,setData] = useState("");
    const[data1,setData1] = useState("");
    const[hot,setHot] = useState("");
    const[trend,setTrend] = useState("");
    const[most,setMost] = useState("");
    const[details,setDetails] = useState("");
    const[active,setActive] = useState("2");

    useEffect(() => {
        if (trend.length > 0) {
          setDetails(trend);
        }
      }, [trend]);

    const handleList = (id,name) =>{
        setActive(id)
        if(hot && hot[0].name === name){
            setDetails(hot)
        }
        else if(trend && trend[0].name === name){
            setDetails(trend)
        }
        else{
            setDetails(most)
        }
    }
    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BACKEND_URL}/hotnews`)
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            setHot(data)
        })
    },[])

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BACKEND_URL}/trendynews`)
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            setTrend(data)
        })
    },[])

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BACKEND_URL}/mostwatch`)
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            setMost(data)
        })
    },[])

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BACKEND_URL}/entertainment`)
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            setData(data)
        })
    },[])

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BACKEND_URL}/sports`)
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            setData1(data)
        })
    },[])

    const navigate = useNavigate();

    const handleClick = (id,name) =>{
        console.log(name)
        navigate(`/view/${name}/${id}`)
    }
    
  return (
    <>
        <div className="mainBodyTwo">
            <div className="mainBodyTwoWrapper">
                <div className="styleOne1">
                    <StyleTwo data={data1}/>
                </div>
                <div className="styleTwo2">
                    <StyleOne data={data}/>
                </div>
                <div className="styleThree">
                    <div className="widgets">
                        <div className="widgetTitle">
                            <h2>फीचर्ड न्यूज</h2>
                            <FaRegNewspaper className='icon'/>
                        </div>

                        <div className="list">
                            <div className="top">
                                <ul>
                                    <li id='hotnews' onClick={()=>handleList('1','hotnews')} className={active === '1'?'active':''}> 
                                        <a className="hotNews">ताजा ख़बर</a>
                                    </li>
                                    <li id='trendynews' onClick={()=>handleList('2','trendynews')} className={active === '2'?'active':''}>
                                        <a className='trendyNews'>ट्रेंडी न्यूज़</a>
                                    </li>
                                    <li id='mostwatch' onClick={()=>handleList('3','mostwatch')} className={active === '3'?'active':''}>
                                        <a className='mostWatched'>ज्यादा देखी गयी</a>
                                    </li>
                                </ul>
                            </div>
                            {details && details.map((v)=>(
                                <div className="bottom">
                                    <div onClick={()=>handleClick(v.id,v.name)} key={v.id} className="item">
                                        <div className="left">
                                            <img style={{width:"110px",height:"auto"}} src={`https://drive.google.com/thumbnail?id=${v.image.image1}&sz=w1000`} alt="" />
                                        </div>
                                        <div className="right">
                                            {/* <ul className='nav'>
                                                <li>Hantu Raya</li>
                                                <li>20 Jan 2023</li>
                                            </ul> */}
                                            <h3>
                                                {v.heading}
                                            </h3>
                                        </div>
                                    </div>

                                </div>
                            ))
                             }
                        </div>
                    </div>

                    <div className="widgets">
                        <div className="widgetTitle">
                            <h2 className="h4">
                                विज्ञापन
                            </h2>
                            <GoMegaphone className="icon"/>
                        </div>

                        <div className="adWidget">
                            <a>
                                <img src="./assets/ad-300x250-2.jpg" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default MainBodyTwo
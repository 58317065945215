import React from 'react'
import './styleThree.scss'
import HeadingTitle from '../headingTitle/HeadingTitle'

const StyleThree = () => {
  return (
    <>
        <div className="styleThree">
            <div className="styleThreeWrapper">
                <div className="widget3">
                    <HeadingTitle title="वोटिंग पोल (चेकबॉक्स)" />
                    <div className="pollWidget">
                        <div className="title">
                            <h3>
                            आपकी राय में अब तक का सर्वश्रेष्ठ क्रिकेटर कौन सा था?
                            </h3>
                        </div>

                        <div className="options">
                            <form>
                                <div className="checkBox">
                                    <label>
                                        <input type="checkbox" />
                                        <span>विराट कोहली</span>
                                    </label>
                                        <p>
                                            <span style={{width:"20%"}}></span>
                                        </p>
                                        <div className="perc">20%</div>
                                </div>

                                <div className="checkBox">
                                    <label>
                                        <input type="checkbox" />
                                        <span>महेंद्र सिंह धोनी</span>
                                    </label>
                                        <p>
                                            <span style={{width:"53%"}}></span>
                                        </p>
                                        <div className="perc">53%</div>
                                </div>

                                <div className="checkBox">
                                    <label>
                                        <input type="checkbox" />
                                        <span>रोहित शर्मा</span>
                                    </label>
                                        <p>
                                            <span style={{width:"12%"}}></span>
                                        </p>
                                        <div className="perc">12%</div>
                                </div>

                                <div className="checkBox">
                                    <label>
                                        <input type="checkbox" />
                                        <span>सचिन तेंडुलकर</span>
                                    </label>
                                        <p>
                                            <span style={{width:"15%"}}></span>
                                        </p>
                                        <div className="perc">15%</div>
                                </div>
                                <button type="submit">अभी मतदान करें</button>
                            </form>
                        </div>
                    </div>
                </div>
                
                <div className="widget3">
                    <HeadingTitle title="वोटिंग पोल (रेडियो)"/>
                    <div className="pollWidget">
                        <h3>
                            क्या आपको लगता है कि मोबाइल फ़ोन पर पैसे भेजने की लागत कम की जानी चाहिए?
                        </h3>
                    <div className="options">
                        <form>
                            <div className="radio">
                                <label>
                                    <input type="radio" name="option-1" />
                                    <span>हाँ</span>
                                </label>

                                <p>
                                    <span style={{width:"60%"}}></span>
                                </p>
                                <div className="perc">60%</div>
                            </div>

                            <div className="radio">
                                <label>
                                    <input type="radio" name="option-1" />
                                    <span>नहीं</span>
                                </label>

                                <p>
                                    <span style={{width:"30%"}}></span>
                                </p>
                                <div className="perc">30%</div>
                            </div>

                            <div className="radio">
                                <label>
                                    <input type="radio" name="option-1" />
                                    <span>औसत</span>
                                </label>

                                <p>
                                    <span style={{width:"10%"}}></span>
                                </p>
                                <div className="perc">10%</div>
                            </div>
                            <button type="submit">अभी मतदान करें</button>
                        </form>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default StyleThree
import React, { useEffect, useState } from 'react'
import './privacy.scss';
import NavbarTop from '../../components/navbarTop/NavbarTop';
import AdvertTop from '../../components/advertTop/AdvertTop';
import NavbarBottom from '../../components/navbarBottom/NavbarBottom';
import Footer from '../../components/footer/Footer';

const Privacy = () => {

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/rules`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const responseData = await response.json();
                setData(responseData);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchData();

    }, []);

  return (
    <div className="privacy">
        <div className="privacyWrapper">
            <NavbarTop></NavbarTop>
            <AdvertTop></AdvertTop>
            <NavbarBottom></NavbarBottom>
            { data && <div className="content">
                <div className="contentWrapper">
                    <h1>{data[0].heading}</h1>
                    <div className="des">
                        <p>{data[0].description[0]}</p>
                        <h2>{data[0].subheading[0]}</h2>
                        <p>{data[0].description[1]}</p>
                        <h2>{data[0].subheading[1]}</h2>
                        <p>{data[0].description[2]}</p>
                        <p>{data[0].description[3]}</p>
                        <p>{data[0].description[4]}</p>
                        <p>{data[0].description[5]}</p>
                        <p>{data[0].description[6]}</p>
                        <h2>{data[0].subheading[2]}</h2>
                        <p>{data[0].description[7]}</p>
                        <h2>{data[0].subheading[3]}</h2>
                    </div>
                </div>
              </div>
            }
            <Footer></Footer>
        </div>
    </div>
  )
}

export default Privacy
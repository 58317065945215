import React, { useEffect, useState } from 'react'
import './mainBodyThree.scss'
import StyleOne from '../styleOne/StyleOne'
import StyleTwo from '../styleTwo/StyleTwo'
import { FaRegEnvelopeOpen } from 'react-icons/fa'
import { RiSendPlaneFill } from 'react-icons/ri'

const MainBodyThree = () => {

    const[data,setData] = useState("");
    const[data1,setData1] = useState("");

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BACKEND_URL}/health`)
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            setData(data)
        })
    },[])

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BACKEND_URL}/lifestyle`)
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            setData1(data)
        })
    },[])

  return (
    <>
        <div className="mainBodyThree">
            <div className="mainBodyThreeWrapper">
                <div className="item">
                    <StyleOne data={data}/>
                </div>
                <div className="item">
                    <StyleTwo data={data1}/>
                </div>
                <div className="widget item">
                    <div className="advertMiddleWrapper">
                    <div className="newsLetter">
                        <div className="top">
                            <h2>समाचारपत्र प्राप्त करें</h2>
                            <FaRegEnvelopeOpen className="icon" />
                        </div>
                        <div className="bottom">
                            <div className="bottomWrapper">
                                <div className="content">
                                    <p>लोकप्रिय समाचार और विशेष अपडेट प्राप्त करने के लिए हमारे न्यूज़लेटर की सदस्यता लें।.</p>
                                </div>
                                <form >
                                    <div className="formInput">
                                        <input 
                                        type="email" 
                                        name="email" 
                                        placeholder="E-mail address" 
                                        autoComplete="off" 
                                        required 
                                        className="formControl"/>
                                            <button type="submit" className="button">
                                                <RiSendPlaneFill style={{fontSize:"25px"}}/>
                                            </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    </div>  
                </div>
            </div>
        </div>
    </>
  )
}

export default MainBodyThree
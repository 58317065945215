import React, { useEffect, useState } from "react";
import {MdLocationOn, MdOutlineLanguage, MdOutlinePersonOutline} from "react-icons/md"
import {WiDayCloudy} from "react-icons/wi"
import {BiCalendar} from "react-icons/bi"
import {RxCaretDown} from "react-icons/rx"
import {RiFacebookFill, RiInstagramFill, RiMediumFill, RiTwitterXFill, RiYoutubeFill} from "react-icons/ri"
import { SiGoogletranslate } from "react-icons/si";
import { FaQuora } from "react-icons/fa";
import './navbarTop.scss'


const NavbarTop = () => {
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [error, setError] = useState(null);
    const [temp,setTemp] = useState("");

    // console.log(temp)

    useEffect(() => {
        // Check if geolocation is supported by the browser
        if (!navigator.geolocation) {
            setError('Geolocation is not supported by your browser');
            return;
        }

        // Get the user's current location
        navigator.geolocation.getCurrentPosition(
            position => {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
            },
            error => {
                setError('Error getting your location: ' + error.message);
            }
        );
    }, []);

    useEffect(() => {
        const fetchWeatherData = async () => {
            try {
                const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=ed54c7a2a565b8d115b11faf5d9e6eef`);
                
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
    
                const data = await response.json();
                const temperature = data.main.temp;
                setTemp((temperature - 273.15).toFixed(0))
                // console.log(`Current temperature in India: ${temperature}°C`);
            } catch (error) {
                console.error('There was a problem fetching weather data:', error);
            }
        };
    
        if (latitude !== null && longitude !== null) {
            fetchWeatherData();
        }
    }, [latitude, longitude]);
    

     console.log(latitude)
     console.log(longitude)
        const currentDate = new Date();

        // day Name
        const dayNames = ['रविवार', 'सोमवार', 'मंगलवार', 'बुधवार', 'गुरुवार', 'शुक्रवार', 'शनिवार'];
        const dayIndex = currentDate.getDay()
        const dayName = dayNames[dayIndex]

        // Array of month names
        const monthNames = ['जनवरी', 'फरवरी', 'मार्च', 'अप्रैल', 'मई', 'जून', 'जुलाई', 'अगस्त', 'सितंबर', 'अक्टूबर', 'नवंबर', 'दिसंबर'];
         const monthIndex = currentDate.getMonth()
         const monthName = monthNames[monthIndex]

    return(
        <>
            <div className="navbarTop">
                <div className="navbarContainer">
                    <div className="left">
                        <div className="item">
                            <MdLocationOn style={{fontSize:"18px"}} />
                            <span>भारत</span>
                        </div>
                        <div className="item">
                            <WiDayCloudy style={{fontSize:"18px"}} />
                            <span>{temp}°C</span>
                        </div>
                        <div className="item">
                            <BiCalendar style={{fontSize:"18px"}} />
                            <span>Today ({dayName} {currentDate.getDate()} {monthName} {currentDate.getFullYear()})</span>
                        </div>
                    </div>
                    <div className="right">
                        {/* <div className="item">
                            <MdOutlinePersonOutline style={{fontSize:"18px"}}/>
                            <span>लॉगिन/रजिस्टर करें</span>
                        </div> */}
                        <div style={{width:"170px"}} className="item">
                            <SiGoogletranslate style={{fontSize:"20px"}}/>
                            <span id="google_translate_element"></span>
                            {/* <div id="google_translate_element"></div> */}
                            <RxCaretDown style={{fontSize:"18px"}}/>
                        </div>
                        <div className="icons">
                            <a href="https://youtube.com/@TheDailyNews0007?si=DVm8O1Fg2bEQbR"><RiYoutubeFill style={{fontSize:"18px"}} /></a>
                            <a href="https://www.instagram.com/thedailynews2024?igsh=MXdybXk0cTN2Zjh3aw%3D%3D&utm_source=qr"><RiInstagramFill style={{fontSize:"18px"}} /></a>
                            <a href="https://twitter.com/Politically08"><RiTwitterXFill style={{fontSize:"16px"}} /></a>
                            <a href="https://www.facebook.com/profile.php?id=61555276393379&mibextid=LQQJ4d"><RiFacebookFill style={{fontSize:"18px"}} /></a>
                            <a href="https://medium.com/@thedailynews2110"><RiMediumFill style={{fontSize:"18px"}} /></a>
                            <a href="https://www.quora.com/profile/Thedaily-News?ch=17&oid=2522663940&share=01422c04&srid=37F21M&target_type=user"><FaQuora style={{fontSize:"18px"}} /></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NavbarTop;
import React, { useEffect, useState } from "react";
import './mainBody.scss';
import StyleOne from "../styleOne/StyleOne";
import StyleTwo from "../styleTwo/StyleTwo";
import Widget from "../widget/Widget";


const MainBody = () =>{
    const[data,setData] = useState("");
    const[data1,setData1] = useState("");

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BACKEND_URL}/india`)
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            setData(data)
        })
    },[])
    // console.log(data)
    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BACKEND_URL}/international`)
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            setData1(data)
        })
    },[])
    console.log(data1)

    return(
        <>
        <div className="mainBody">
        <div className="mainBodyWrapper">
            <div className="item">
                <StyleOne data={data}/>
            </div>
            <div className="item">
                <StyleTwo data={data1}/>
            </div>   
            <div className="widget item">
                <Widget/>
            </div>
        </div>

        </div>
        </>
    )
};

export default MainBody;
import React from 'react'
import './styleOne.scss'
import HeadingTitle from '../headingTitle/HeadingTitle';
import { MdFlashOn } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const StyleOne = ({data}) => {
    console.log(data && data[0].image.image1)
    const navigate = useNavigate();

    const handleClick = (id,name) =>{
        
        navigate(`/view/${name}/${id}`)
    }
    // console.log(data && data[0].image.image1)
  return (
    <>  
        {data && (
            <div className="styleOne">
                <HeadingTitle title={data[0].category}/>
                <div onClick={()=>handleClick(data[0].id,data[0].name)} id={data[0].id} className="top">
                    <img src={`https://drive.google.com/thumbnail?id=${data[0].image.image1}&sz=w1000`} alt="" />
                    <a className="cat">{data[0].title}</a>
                    <a className="icon"><MdFlashOn style={{fontSize:"20px"}} /></a>
                    <div className="postInfo">
                        <ul className="nav">
                            {/* <li>{headingAuthor}</li> */}
                            {/* <li>{headingDate}</li> */}
                        </ul>
                        <h3>{data[0].heading}</h3>
                    </div>
                </div>

                <div className="bottom">
                <div className="item">
                    <div onClick={()=>handleClick(data[1].id,data[0].name)} id={data[1].id} className="left">
                        <a className='thumb'>
                            <img src={`https://drive.google.com/thumbnail?id=${data[1].image.image1}&sz=w1000`} alt="" />
                            <ul className='nav'>
                                {/* <li>{image1Author}</li> */}
                                {/* <li>{image1Date}</li> */}
                            </ul>
                            <h3>{data[1].heading}</h3>
                        </a>
                    </div>
                    <div  onClick={()=>handleClick(data[2].id,data[0].name)} id={data[2].id} className="right">
                    <a  className='thumb'>
                            <img  src={`https://drive.google.com/thumbnail?id=${data[2].image.image1}&sz=w1000`} alt="" />
                            <ul className='nav'>
                                {/* <li>{image2Author}</li> */}
                                {/* <li>{image2Date}</li> */}
                            </ul>
                            <h3>{data[2].heading}</h3>
                        </a>
                    </div>
                </div>
                <div className="item">
                    <div onClick={()=>handleClick(data[3].id,data[0].name)} id={data[3].id} className="left">
                    <a className='thumb'>
                            <img src={`https://drive.google.com/thumbnail?id=${data[3].image.image1}&sz=w1000`} alt="" />
                            <ul className='nav'>
                                {/* <li>{image3Author}</li> */}
                                {/* <li>{image3Date}</li> */}
                            </ul>
                            <h3>{data[3].heading}</h3>
                        </a>
                    </div>
                    <div onClick={()=>handleClick(data[4].id,data[0].name)} id={data[4].id} className="right">
                    <a className='thumb'>
                            <img src={`https://drive.google.com/thumbnail?id=${data[4].image.image1}&sz=w1000`} alt="" />
                            <ul className='nav'>
                                {/* <li>{image4Author}</li> */}
                                {/* <li>{image4Date}</li> */}
                            </ul>
                            <h3>{data[4].heading}</h3>
                        </a>
                    </div>
                </div>
                </div>
            </div>
        )}
    </>
  )
}

export default StyleOne;
import React from 'react'
import './styleTwo.scss'
import HeadingTitle from '../headingTitle/HeadingTitle';
import { useNavigate } from 'react-router-dom';
import { MdFavoriteBorder } from 'react-icons/md';

const StyleTwo = ({ data }) => {
  const navigate = useNavigate();

  const handleClick = (id,name) =>{
      
      navigate(`/view/${name}/${id}`)
  }
  return (
    <>
      {data && 
        <div className="styleTwo">
          <HeadingTitle title={data[0].category}/>
          <div onClick={()=>handleClick(data[0].id,data[0].name)} id={data[0].id} className="top">
            <img src={`https://drive.google.com/thumbnail?id=${data[0].image.image1}&sz=w1000`} alt="" />
            <a className="cat">{data[0].title}</a>
            <a className="icon"><MdFavoriteBorder style={{ fontSize: "20px" }} /></a>

            <div className="postInfo">
              <ul className="nav">
                {/* <li>{headingAuthor}</li> */}
                {/* <li>{headingDate}</li> */}
              </ul>
              <h3>{data[0].heading}</h3>
            </div>
          </div>
          <div className="bottom">

            <div onClick={()=>handleClick(data[1].id,data[0].name)} id={data[1].id}  className="item">
              <div className="left">
                <img src={`https://drive.google.com/thumbnail?id=${data[1].image.image1}&sz=w1000`} alt="" />
              </div>
              <div className="right">
                <ul className="nav">
                  {/* <li>{image1Author}</li> */}
                  {/* <li>{image1Date}</li> */}
                </ul>
                <h3>{data[1].heading}</h3>
              </div>
            </div>

            <div onClick={()=>handleClick(data[2].id,data[0].name)} id={data[2].id} className="item">
              <div className="left">
                <img src={`https://drive.google.com/thumbnail?id=${data[2].image.image1}&sz=w1000`} alt="" />
              </div>
              <div className="right">
                <ul className="nav">
                  {/* <li>{image2Author}</li> */}
                  {/* <li>{image2Date}</li> */}
                </ul>
                <h3>{data[2].heading}</h3>
              </div>
            </div>

            <div onClick={()=>handleClick(data[3].id,data[0].name)} id={data[3].id} className="item">
              <div className="left">
                <img src={`https://drive.google.com/thumbnail?id=${data[3].image.image1}&sz=w1000`} alt="" />
              </div>
              <div className="right">
                <ul className="nav">
                  {/* <li>{image3Author}</li> */}
                  {/* <li>{image3Date}</li> */}
                </ul>
                <h3>{data[3].heading}</h3>
              </div>
            </div>

            <div onClick={()=>handleClick(data[4].id,data[0].name)} id={data[4].id} className="item">
              <div className="left">
                <img src={`https://drive.google.com/thumbnail?id=${data[4].image.image1}&sz=w1000`} alt="" />
              </div>
              <div className="right">
                <ul className="nav">
                  {/* <li>{image4Author}</li> */}
                  {/* <li>{image4Date}</li> */}
                </ul>
                <h3>{data[4].heading}</h3>
              </div>
            </div>
          
          </div>
        </div>
      }  
    </>
  )
}

export default  StyleTwo;
import React from "react";
import Home from "./pages/home/home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import View from "./pages/view/View";
import Privacy from "./pages/privacy/Privacy";


function App() {
  return (
    <>
      <div className="app">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home/>}></Route>
            <Route path="/view/:name/:id" element={<View/>}></Route>
            <Route path="/privacy" element={<Privacy/>}></Route>
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;

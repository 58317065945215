import React from 'react'
import './widget.scss'
import { FaFacebookF, FaInstagram, FaQuora, FaShareAlt } from 'react-icons/fa'
import { FaMedium, FaXTwitter } from "react-icons/fa6";
import {TfiYoutube} from "react-icons/tfi"

const Widget = () => {
  return (
    <>
        <div className="widget">
            <div className="top">
                <a>
                    <img src="./assets/ad-300x250-1.jpg" alt="" />
                </a>
            </div>
            <div className="center">
                <h2>जुड़े रहो</h2>
                <FaShareAlt/>
            </div>
            <div className="bottom">
                <ul className="nav">
                    <li className='facebook'>
                        <a href='https://www.facebook.com/profile.php?id=61555276393379&mibextid=LQQJ4d'>
                            <span className='icon'>
                                <FaFacebookF
                                    className='facebookicon'
                                    style={{fontSize:"20px"}}
                                />
                            </span>
                            <span>फेसबुक</span>
                        </a>
                    </li>

                    <li className='x'>
                        <a href='https://twitter.com/Politically08'>
                            <span className='icon'>
                                <FaXTwitter
                                    className='xicon'
                                    style={{fontSize:"20px"}}
                                />
                            </span>
                            <span>ट्विटर</span>
                        </a>
                    </li>

                    <li className='insta'>
                        <a href='https://www.instagram.com/thedailynews2024?igsh=MXdybXk0cTN2Zjh3aw%3D%3D&utm_source=qr'>
                            <span className='icon'>
                                <FaInstagram
                                    className='instaicon'
                                    style={{fontSize:"20px"}}
                                />
                            </span>
                            <span>ईनस्टाग्राम</span>
                        </a>
                    </li>

                    <li className='youtube'>
                        <a href='https://youtube.com/@TheDailyNews0007?si=DVm8O1Fg2bEQbR'>
                            <span className='icon'>
                                <TfiYoutube
                                    className='yticon'
                                    style={{fontSize:"20px"}}
                                />
                            </span>
                            <span>यूट्यूब</span>
                        </a>
                    </li>

                    <li className='medium'>
                        <a href='https://medium.com/@thedailynews2110'>
                            <span className='icon'>
                                <FaMedium
                                    className='mdticon'
                                    style={{fontSize:"20px"}}
                                />
                            </span>
                            <span>मीडियम</span>
                        </a>
                    </li>

                    
                    <li className='quora'>
                        <a href='https://www.quora.com/profile/Thedaily-News?ch=17&oid=2522663940&share=01422c04&srid=37F21M&target_type=user'>
                            <span className='icon'>
                                <FaQuora
                                    className='qrticon'
                                    style={{fontSize:"20px"}}
                                />
                            </span>
                            <span>क्योंरा(Quora)</span>
                        </a>
                    </li>

                </ul>
            </div>
        </div>
    </>
  )
}

export default Widget
import React from 'react'
import './footer.scss'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
        <div className="footer">
            <div className="wrapper">
                <ul>
                    <li className="logo-1"><img src="./assets/logo.png" alt=""/></li>
                    {/* <ul className="list1">
                        <li className="info">फॉलो करे</li>
                        <li>
                            <div className="icons">
                                <i className="fa-brands fa-youtube" style={{padding:"10px 10px"}}></i>
                                <i className="fa-brands fa-x-twitter" style={{padding:"10px 10px"}}></i>
                                <i className="fa-brands fa-instagram" style={{padding:"10px 12px"}}></i>
                                <i className="fa-brands fa-facebook-f" style={{padding:"10px 13px"}}></i>
                                <i className="fa-brands fa-quora" style={{padding:"10px 12px"}}></i>
                                <i className="fa-brands fa-medium" style={{padding:"10px 10px"}}></i>
                            </div>
                        </li>
                    </ul> */}
                </ul>

                <ul>
                    <li className="info">संपर्क में रहो</li>
                    {/* <li style={{paddingBlock: "10px"}}>A/901, Navratna Corporate Park,Near Ashok Vatika,<br/>
                        Iscon Ambli Rd Ahmedabad, Gujarat 380058, India</li> */}
                    <li>Mail: <a href="mailto:Info@thedailynewscast.com">Info@thedailynewscast.com</a></li>
                    {/* <li>फोन: <a href="tel:+91-8238234631"> +91-8238234631</a> </li> */}
                </ul>

                {/* <ul>
                    <li className="info" >मुख्य लिंक्स</li>
                    <li><a href="#23">हमारे बारे में</a></li>
                    <li><a href="#23">सेवा</a></li>
                    <li><a href="#23">प्रकाशन</a></li>
                    <li><a href="#23">हमारे ऐप्स</a></li>
                    <li><a href="#23">समर्थन अनुरोध</a></li>
                    <li><a href="#23">संपर्क करें</a></li>
                </ul> */}

                    <ul className="list1">
                        <li className="info">फॉलो करे</li>
                        <li>
                            <div className="icons">
                                <a href="https://youtube.com/@TheDailyNews0007?si=DVm8O1Fg2bEQbR"><i className="fa-brands fa-youtube" style={{padding:"10px 10px"}}></i></a>
                                <a href="https://twitter.com/Politically08"><i className="fa-brands fa-x-twitter" style={{padding:"10px 10px"}}></i></a>
                                <a href="https://www.instagram.com/thedailynews2024?igsh=MXdybXk0cTN2Zjh3aw%3D%3D&utm_source=qr"><i className="fa-brands fa-instagram" style={{padding:"10px 12px"}}></i></a>
                                <a href="https://www.facebook.com/profile.php?id=61555276393379&mibextid=LQQJ4d"><i className="fa-brands fa-facebook-f" style={{padding:"10px 13px"}}></i></a>
                                <a href="https://www.quora.com/profile/Thedaily-News?ch=17&oid=2522663940&share=01422c04&srid=37F21M&target_type=user"><i className="fa-brands fa-quora" style={{padding:"10px 12px"}}></i></a>
                                <a href="https://medium.com/@thedailynews2110"><i className="fa-brands fa-medium" style={{padding:"10px 10px"}}></i></a>
                            </div>
                        </li>
                    </ul>

                <ul>
                    {/* <li className="info">कंपनी</li> */}
                    {/* <li><a href="#23">हमारे बारे में</a></li> */}
                    {/* <li><a href="#23">भागीदार</a></li> */}
                    {/* <li><a href="#23">नेतृत्व</a></li> */}
                    {/* <li><a href="#23">करियर</a></li> */}
                    {/* <li><a href="#23">समाचार एवं घटनाक्रम</a></li> */}
                    <ul className="list1">
                    <li className="info">कानूनी</li>
                    <Link to={"/privacy"}>
                        <li><a>गोपनीयता नीति</a></li>
                    </Link>
                    <Link to={"/privacy"}>
                        <li><a>उपयोग की शर्तें</a></li>
                    </Link>
                    </ul>
                </ul>

            </div>
            <div className="wrapper2">
                <p>Copyright © 2024 The Daily News. All Rights Reserved.</p>
            </div>
        </div>
    </>
  )
}

export default Footer
import React, { useEffect, useState } from 'react'
import './mainBodyFour.scss'
import StyleOne from '../styleOne/StyleOne'
import StyleTwo from '../styleTwo/StyleTwo'
import StyleThree from '../styleThree.jsx/StyleThree'

const MainBodyFour = () => {

    const[data,setData] = useState("");
    const[data1,setData1] = useState("");

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BACKEND_URL}/economy`)
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            setData(data)
        })
    },[])

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BACKEND_URL}/election`)
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            setData1(data)
        })
    },[])
  return (
    <>
        <div className="mainBodyFour">
            <div className="mainBodyFourWrapper">
                <div className="styleOne1">
                    <StyleTwo data={data1} />
                </div>
                <div className="styleTwo2">
                    <StyleOne data={data}/>
                </div>
                <div className="styleThree3">
                    <StyleThree/>
                </div>
            </div>
        </div>
    </>
  )
}

export default MainBodyFour
import React from "react";
import './home.scss';
import NavbarTop from "../../components/navbarTop/NavbarTop";
import AdvertTop from "../../components/advertTop/AdvertTop";
import NavbarBottom from "../../components/navbarBottom/NavbarBottom";
import Hotlinks from "../../components/hotlinks/Hotlinks";
import MainContent from "../../components/mainContent/MainContent";
import MainBody from "../../components/mainBody/MainBody";
import MainBodyTwo from "../../components/mainBodyTwo/MainBodyTwo";
import MainBodyThree from "../../components/mainBodyThree/MainBodyThree";
import AudioVideo from "../../components/audioVideo/AudioVideo";
import MainBodyFour from "../../components/mainBodyFour/MainBodyFour";
import Footer from "../../components/footer/Footer";



const Home = () =>{
    
    return(
        <>
            <div className="home">
                <div className="homeWrapper">
                    <NavbarTop></NavbarTop>
                    <AdvertTop></AdvertTop>
                    <NavbarBottom></NavbarBottom>
                    <Hotlinks></Hotlinks>
                    <MainContent></MainContent>
                    <MainBody id="mainBody"></MainBody>
                    <MainBodyTwo id="mainBodyTwo"></MainBodyTwo>
                    <MainBodyThree id="mainBodyThree"></MainBodyThree>
                    <AudioVideo id="audioVideo"></AudioVideo>
                    <MainBodyFour id="mainBodyFour"></MainBodyFour>
                    <Footer></Footer>
                </div>
            </div>
        </>
    )
}

export default Home;
import React from "react";
import "./advertTop.scss"

const AdvertTop = () =>{
    return(
        <>
        <div className="advertTop">
            <div className="advertTopContainer">
                <div className="left">
                    <a>
                        <img style={{width:"120px"}} src="./assets/logo.png" alt="The Daily News Logo"/>
                    </a>
                </div>
                <div className="righ">
                    <a>
                        <img style={{width:"400px"}} src="./assets/advertttt.gif" alt="Advertisement"/>
                    </a>
                </div>
            </div>
        </div>
        </>
    )
}

export default AdvertTop;
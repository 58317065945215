import React, { useEffect, useState } from "react";
import "./mainContent.scss"
import { MdFlashOn } from "react-icons/md";
import { useNavigate } from "react-router-dom";


const MainContent = () => {
    const[data,setData] = useState("");

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BACKEND_URL}/breakingnews`)
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            setData(data)
        })
    },[])

    const navigate = useNavigate();

    const handleClick = (id,name) =>{
        
        navigate(`/view/${name}/${id}`)
    }

    return(
        <>
        {data && 
            <div className="mainContent">
                <div className="col">
                    <div className="row">
                        <img src={`https://drive.google.com/thumbnail?id=${data[0].image.image1}&sz=w1000`} alt="" />
                        <a className="cat">{data[0].category}</a>
                        <a className="icon"> 
                        {" "}
                        <MdFlashOn style={{fontSize:"20px"}} /> 
                        </a>

                        <div onClick={()=>handleClick(data[0].id,data[0].name)} className="postInfo">
                            {/* <ul className="nav">
                                <li>Molly Nagle</li>
                                <li>10 March 2024</li>
                            </ul> */}
                            <h3>{data[0].heading}</h3>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <img src={`https://drive.google.com/thumbnail?id=${data[1].image.image1}&sz=w1000`} alt="" />
                                <a className="cat">{data[1].category}</a>
                                <a className="icon">
                                    <MdFlashOn style={{
                                        fontSize:"20px"
                                    }}/>
                                </a>

                                <div onClick={()=>handleClick(data[1].id,data[0].name)} className="postInfo">
                                    {/* <ul className="nav">
                                        <li>Molly Nagle</li>
                                        <li>10 March 2024</li>
                                    </ul> */}
                                    <h3>{data[1].heading.slice(0,29)}....</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <img src={`https://drive.google.com/thumbnail?id=${data[2].image.image1}&sz=w1000`} alt="" />
                                <a className="cat">{data[2].category}</a>
                                <a className="icon">
                                    <MdFlashOn style={{
                                        fontSize:"20px"
                                    }}/>
                                </a>

                                <div onClick={()=>handleClick(data[2].id,data[0].name)} className="postInfo">
                                    {/* <ul className="nav">
                                        <li>Molly Nagle</li>
                                        <li>10 March 2024</li>
                                    </ul> */}
                                    <h3 >{data[2].heading.slice(0,29)}....</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div onClick={()=>handleClick(data[3].id,data[0].name)} className="row">
                        <img style={{height:"200px"}} src={`https://drive.google.com/thumbnail?id=${data[3].image.image1}&sz=w1000`} alt="" />
                        <a className="cat">{data[3].category}</a>
                        <a className="icon">
                            <MdFlashOn style={{
                                fontSize:"20px"
                            }}/>
                        </a>

                        <div onClick={()=>handleClick(data[3].id,data[0].name)} className="postInfo">
                            {/* <ul className="nav">
                                <li>Molly Nagle</li>
                                <li>10 March 2024</li>
                            </ul> */}
                            <h3>{data[3].heading}</h3>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default MainContent;
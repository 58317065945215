import React, { useEffect, useState } from 'react';
import './view.scss';
import NavbarBottom from '../../components/navbarBottom/NavbarBottom';
import AdvertTop from '../../components/advertTop/AdvertTop';
import NavbarTop from '../../components/navbarTop/NavbarTop';
import Footer from '../../components/footer/Footer';
import { useParams } from 'react-router-dom';

const View = () => {
    const { name, id } = useParams();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    console.log(name);
    console.log(id);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/${name}/${id}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const responseData = await response.json();
                setData(responseData);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchData();

    }, [name, id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

  return (
    <>
        <div className="view">
                <div className="viewWrapper">
                    <NavbarTop></NavbarTop>
                    <AdvertTop></AdvertTop>
                    <NavbarBottom></NavbarBottom>
                    {data && <div className="content">
                        <div className="contentWrapper">
                            <h1>{data[0].heading}</h1>
                            <div className="img">
                                <img src={`https://drive.google.com/thumbnail?id=${data[0].image.image1}&sz=w1000`} alt="image"/>
                            </div>
                            <div className="des">
                                <h2>{data[0].subheading[0]}</h2>
                                <p>{data[0].description[0]}</p>
                                <div className="img1">
                                    <img src={`https://drive.google.com/thumbnail?id=${data[0].image.image2}&sz=w1000`} alt="image1" />
                                </div>
                                <h2>{data[0].subheading[1]}</h2>
                                <p>{data[0].description[1]}</p>
                                <div className="img2">
                                    <img src={`https://drive.google.com/thumbnail?id=${data[0].image.image3}&sz=w1000`} alt="image1" />
                                </div>
                                <h2>{data[0].subheading[2]}</h2>
                                <p>{data[0].description[2]}</p>
                                <h2>{data[0].subheading[3]}</h2>
                                <p>{data[0].description[3]}</p>
                                <h2>{data[0].subheading[4]}</h2>
                                <p>{data[0].description[4]}</p>
                            </div>
                        </div>
                    </div>}
                    <Footer></Footer>
                </div>
        </div>
    </>
  )
}

export default View;